import { ADD_ATTENDANCE_REQUEST, ADD_ATTENDANCE_SUCCESS, ADD_ATTENDANCE_FAIL, ADD_MANUAL_REQUEST, ADD_MANUAL_SUCCESS, ADD_MANUAL_FAIL, CLEAR_SCANNER_AUTO, CLEAR_SCANNER_MANUAL, CONFIRM_MANUAL_REQUEST, CONFIRM_MANUAL_SUCCESS, CONFIRM_MANUAL_FAIL } from '../types/scannerTypes';

const initialState = {
    current: null,
    currentManual: null,
    loading: false,
    loadingManual: false,
    loadingConfirm: false,
    error: null,
    errorManual: null,
    errorConfirm: null,
    messageConfirm: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_ATTENDANCE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case ADD_ATTENDANCE_SUCCESS:
            return {
                ...state,
                current: action.payload,
                loading: false,
                error: null,
            };
        case ADD_ATTENDANCE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload || 'Internal server error'
            };
        case ADD_MANUAL_REQUEST:
            return {
                ...state,
                loadingManual: true,
                errorManual: null,
            };
        case ADD_MANUAL_SUCCESS:
            return {
                ...state,
                loadingManual: false,
                errorManual: null,
                currentManual: action.payload,
            };
        case ADD_MANUAL_FAIL:
            return {
                ...state,
                loadingManual: false,
                errorManual: action.payload || 'Internal server error'
            };
        case CONFIRM_MANUAL_REQUEST:
            return {
                ...state,
                loadingConfirm: true,
                errorConfirm: null,
                currentManual: null
            };
        case CONFIRM_MANUAL_SUCCESS:
            return {
                ...state,
                loadingConfirm: false,
                errorConfirm: null,
                messageConfirm: action.payload,
            };
        case CONFIRM_MANUAL_FAIL:
            return {
                ...state,
                loadingConfirm: false,
                messageConfirm: null,
                errorConfirm: action.payload
            };
        case CLEAR_SCANNER_AUTO:
            return {
                ...state,
                loading: false,
                error: null,
                current: null
            };
        case CLEAR_SCANNER_MANUAL: 
            return {
                ...state,
                loadingManual: false,
                errorManual: null,
                currentManual: null,
                loadingConfirm: false,
                errorConfirm: null,
                messageConfirm: null
            };
        default:
            return state;
    }
};