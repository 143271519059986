import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PulseLoader from 'react-spinners/PulseLoader';
import moment from 'moment';

import { addManualScan, addManualScanConfirm } from '../../actions/scannerActions';
import { CLEAR_SCANNER_MANUAL } from '../../types/scannerTypes';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import Content from '../../components/layout/Content';
import BottomNav from '../../components/layout/BottomNav';
import Alert from '../../components/layout/Alert';
import Confirmation from '../../components/layout/Confirmation';

import { ReactComponent as Cross } from '../../assets/cross.svg';
import { ReactComponent as Check } from '../../assets/check.svg';

const ManualScanner = () => {
    const dispatch = useDispatch();
    const scannerSelector = useSelector((state) => state.scanner);
    const authSelector = useSelector((state) => state.auth);
    const { loadingManual, currentManual, errorManual, messageConfirm, loadingConfirm, errorConfirm } = scannerSelector;
    const { showTableScan } = authSelector
    const [alert, setAlert] = useState(null);
    const [confirmation, setConfirmation] = useState(null);
    const [customer, setCustomer] = useState('');
    const [table, setTable] = useState('');

    const handleManualScan = () => {
        dispatch(addManualScan(customer));
    };

    const handleConfirm = () => {
        dispatch(addManualScanConfirm(currentManual?.customer_id, table));
    };

    const handleDismiss = () => {
        setAlert(null);
    };

    const handleDeny = () => {
        setCustomer('');
        setConfirmation(null);
        dispatch({ type: CLEAR_SCANNER_MANUAL });
    };

    const handleSetTable = (table) => {
        setTable(table);
    };

    useEffect(() => {
        return () => {
            dispatch({ type: CLEAR_SCANNER_MANUAL });
        };
    }, []);

    useEffect(() => {
        if (!currentManual && errorManual) {
            setAlert({
                icon: <Cross style={{ color: '#e53e3e' }} />,
                message: errorManual
            })
        }

        if (currentManual) {
            const dateofbirth = currentManual?.customer_dateofbirth ? moment(currentManual?.customer_dateofbirth).format('DD-MM-YYYY') : 'No date given';
            let message = currentManual?.customer_firstname + " " + currentManual?.customer_lastname;
            message+= "\nDOB: " + dateofbirth;
            setAlert(null);
            setConfirmation({
                icon: <Check style={{ color: '#48BB78' }} />,
                message
            });
        }

    }, [currentManual, errorManual]);
    
    useEffect(() => {
        if (!messageConfirm && errorConfirm) {
            setAlert({
                icon: <Cross style={{ color: '#e53e3e' }} />,
                message: errorConfirm
            })
        }

        if (messageConfirm) {
            setConfirmation(null);
            setAlert({
                icon: <Check style={{ color: '#48BB78' }} />,
                message: messageConfirm
            });
            setCustomer('');
            setTable('');
            dispatch({ type: CLEAR_SCANNER_MANUAL });
        }

    }, [messageConfirm, errorConfirm]);
    
    return (
        <Fragment>
            <Header />
            <Menu />
            <Content>
                <h1>Guest Search</h1>
                <div className="form-group">
                    <label className="form__label" htmlFor="customer">Mobile/Email Address</label>
                    <input className="form__input" type="text" id="customer" name="customer" value={customer} autoComplete={false} onChange={(e) => setCustomer(e.target.value)} />
                </div>
                <div className="form__buttons">
                    <button aria-label="Register" disabled={loadingManual} className="form__button form__button--inline" onClick={handleManualScan}>Search <PulseLoader loading={loadingManual} color={'#ffffff'} css={'margin-left: 8px'} size={5} /></button>
                </div>
                {alert && (<Alert icon={alert.icon} message={alert.message} dismiss={handleDismiss} />)}
                {confirmation && (<Confirmation tableScan={showTableScan} setTable={handleSetTable} icon={confirmation.icon} message={confirmation.message} dismiss={handleDeny} confirm={handleConfirm} loading={loadingConfirm} />)}
            </Content>
            <BottomNav />
        </Fragment>
    )
}

export default ManualScanner;
