import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';

const TablePopup = ({ onSubmit, onChange, loading, table }) => {
    return (
        <div className="alert">
            <div className="alert__container">
                <div className="form-group">
                    <label className="form__label" htmlFor="table">Table</label>
                    <input className="form__input" type="text" onChange={(e) => onChange(e.target.value)} value={table} />
                </div>
                <div className="alert__buttons">
                    <button className="alert__btn confirm" onClick={onSubmit} disabled={loading}>Confirm <PulseLoader loading={loading} color={'#ffffff'} css={'margin-left: 8px'} size={5} /></button>
                </div>
            </div>
        </div>
    )
}

export default TablePopup;
