import { CREATE_CUSTOMER_REQUEST, CREATE_CUSTOMER_SUCCESS, CREATE_CUSTOMER_FAIL, CLEAR_CREATE_CUSTOMER } from '../types/registerCustomerTypes';

const initialState = {
    loading: false,
    errors: null,
    success: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CREATE_CUSTOMER_REQUEST:
            return {
                ...state,
                loading: true,
                errors: null,
                success: false
            };
        case CREATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            };
        case CREATE_CUSTOMER_FAIL:
            return {
                ...state,
                loading: false,
                errors: action.payload
            };
        case CLEAR_CREATE_CUSTOMER:
            return {
                ...state,
                loading: false,
                errors: null,
                success: false
            };
        default:
            return state;
    }
};