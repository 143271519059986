import axios from 'axios';
import Cookie from 'js-cookie';

import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT } from '../types/authTypes';

export const login = (email, password) => async dispatch => {
    try {
        dispatch({ type: LOGIN_REQUEST });

        const { data } = await axios.post('/app_handler.php', { email, password }, { headers: { 'WG-Method': 'LOGIN' }});

        Cookie.set('accessToken', data.jwt, { expires: 30 });
        Cookie.set('user', JSON.stringify(data.user));
        dispatch({ type: LOGIN_SUCCESS, payload: data.user });
    } catch (error) {
        dispatch({ type: LOGIN_FAIL, payload: error?.response.data.errors });
    }
}

export const logout = () => dispatch => {
    dispatch({ type: LOGOUT });
    Cookie.remove('accessToken');
    Cookie.remove('user');
};