import React from 'react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as Venue } from '../../assets/venue.svg';
import { ReactComponent as Camera } from '../../assets/camera.svg';
import { ReactComponent as Manual } from '../../assets/manual-entry.svg';
import { ReactComponent as Guest } from '../../assets/search.svg';
import './BottomNav.css';

const BottomNav = () => {
    return (
        <div className="bottomNav">
            <NavLink to="/" exact className="bottomNav__link" activeClassName="active"><Camera style={{ width: '33px', height: '33px' }} />Scanner</NavLink>
            <NavLink to="/guest-search" className="bottomNav__link" activeClassName="active"><Guest style={{ width: '28px', height: '28px', paddingBottom: '4px' }} />Guest Search</NavLink>
            <NavLink to="/manual-entry" className="bottomNav__link" activeClassName="active"><Manual style={{ width: '28px', height: '28px', paddingBottom: '4px' }} />Manual Entry</NavLink>
            {/* <NavLink to="/venue" className="bottomNav__link" activeClassName="active"><Venue style={{ width: '33px', height: '33px' }} />Venue</NavLink> */}
        </div>
    )
}

export default BottomNav;
