import { combineReducers } from 'redux';

import authReducer from './authReducer';
import appReducer from './appReducer';
import scannerReducer from './scannerReducer';
import registerCustomerReducer from './registerCustomerReducer';

export default combineReducers({
    app: appReducer,
    auth: authReducer,
    scanner: scannerReducer,
    registerCustomer: registerCustomerReducer,
});