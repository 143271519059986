import { TOGGLE_MENU, CLOSE_MENU, NAVIGATION_SUCCESS, MENU_REQUEST, MENU_SUCCESS, MENU_FAIL } from '../types/appTypes';

const initialState = {
    navigation: [],
    menu: null,
    menuOpen: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case NAVIGATION_SUCCESS:
            return {
                ...state,
                navigation: action.payload
            };
        case MENU_REQUEST:
            return {
                ...state,
                menu: null
            };
        case MENU_SUCCESS:
            return {
                ...state,
                menu: action.payload
            };
        case MENU_FAIL:
            const { menu } = require('../data.json');

            return {
                ...state,
                menu 
            };
        case TOGGLE_MENU:
            return {
                ...state,
                menuOpen: !state.menuOpen
            };
        case CLOSE_MENU:
            return {
                ...state,
                menuOpen: false
            };
        default:
            return state;
    }
};