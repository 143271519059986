import React from 'react';

import './Alert.css';

const Alert = ({ icon, message, dismiss }) => {
    return (
        <div className="alert">
            <div className="alert__container">
                <div className="alert__icon">{icon}</div>
                <div className="alert__message">{message}</div>
                <div className="alert__buttons">
                    <button className="alert__btn" onClick={dismiss}>Ok</button>
                </div>
            </div>
        </div>
    )
}

export default Alert;
