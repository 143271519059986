import Cookie from 'js-cookie';

import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT } from '../types/authTypes';

const user = Cookie.getJSON('user');

const initialState = {
    user: user?.display_name || null,
    isAuthenticated: Cookie.getJSON('user') ? true : false,
    showTableScan: user?.show_tablescan || false,
    loading: false,
    errors: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                errors: null,
                user: null,
                isAuthenticated: false,
                showTableScan: false,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload.display_name,
                isAuthenticated: true,
                showTableScan: action.payload.show_tablescan
            };
        case LOGIN_FAIL:
            return {
                ...state,
                loading: false,
                errors: action.payload
            };
        case LOGOUT:
            return {
                ...state,
                user: null,
                isAuthenticated: false,
                errors: null
            };
        default:
            return state;
    }
};